import styles from "./navbar.module.css";

export function Navbar() {
  return (
    <div className={styles.navbarContainer}>
      <nav className={styles.navbar}>
        <div className="logo"></div>

        <ul className={styles.navLinks}>
          <input type="checkbox" id="checkbox_toggle" />
          <label htmlFor="checkbox_toggle" className={styles.hamburger}>
            &#9776;
          </label>

          <div className={styles.menu}>
            <li>
              <a href="#buy">Comprar entradas</a>
            </li>
            <li>
              <a href="#">Artistas</a>
            </li>
            <li>
              <a href="#sponsors">Patrocinadores</a>
            </li>

            <li className={styles.services}>
              <a href="#">Redes Sociales</a>

              {/* <ul className={styles.dropdown}>
                <li>
                  <a href="/">Instagram</a>
                </li>
                <li>
                  <a href="/">Facebook</a>
                </li>
                <li>
                  <a href="/">Tik Tok</a>
                </li>
              </ul> */}
            </li>
          </div>
        </ul>
      </nav>
    </div>
  );
}
