import styles from "./footer.module.css";
import logo from "../../assets/logo.svg";
import sheraton from "../../assets/sheraton.svg";
import presidente from "../../assets/presidente.svg";

export default function Footer() {
  return (
    <div className={styles.footerCont}>
      <img src={logo} className={styles.logoFooter} alt="logo"></img>
      <div className={styles.rightsReserved}>
        All rights reserved © {new Date().getFullYear()}
      </div>
      <div style={{ display: "flex", gap: 20 }}>
        <img
          src={sheraton}
          alt="Hotel Sheraton Presidente"
          className={styles.organizations}></img>
        <img
          src={presidente}
          alt="Presidente Plaza"
          className={styles.organizations}></img>
      </div>
      <div className={styles.rightsReservedMobile}>
        All rights reserved © {new Date().getFullYear()}
      </div>
    </div>
  );
}
