import styles from "./sponsors.module.css";
import sponsors from "../../assets/sponsors/marcas.svg";

export default function Sponsors() {
  return (
    <div className={styles.sponsorsContainer}>
      <h2 id="sponsors">Patrocinadores</h2>
      <div>
        <img src={sponsors} alt="Mahou"></img>
      </div>
    </div>
  );
}
