import presidenteBlue from "../../assets/presidenteBlue.svg";
import styles from "./coming.module.css";
import ticketGold from "../../assets/ticketGold.svg";
import ticketNormal1 from "../../assets/ticketNormal1.svg";
import ticketNormal2 from "../../assets/ticketNormal2.svg";

export default function ComingSoon() {
  return (
    <div id="buy" className={styles.comingContainer}>
      <div className={styles.textComing}>
        <h2>ADQUIERE AQUÍ TUS ENTRADAS</h2>
        <h4>
          Selecciona el día de tu preferencia ó asiste ambos días comprando el
          pase doble
        </h4>
      </div>

      <div className={styles.cardDatesContainer}>
        <div className={styles.cardDates}>
          <img src={ticketGold} alt="Entrada Ticket Viernes Y Sábado"></img>
          <button>
            <a
              rel="noopener noreferrer nofollow"
              target="_blank"
              className={styles.buttonBuy}
              href="https://barlleno.app/tabs/share/home/brick/xRjT2WYqOCjzuodguaHU">
              Comprar Entrada
            </a>
          </button>
        </div>
        <div className={styles.cardDates}>
          <img src={ticketNormal1} alt="Entrada Ticket Viernes"></img>
          <button>
            <a
              className={styles.buttonBuy}
              rel="noopener noreferrer nofollow"
              target="_blank"
              href="https://barlleno.app/tabs/share/home/brick/iNKS7Fzz4uXqLSsIFF05">
              Comprar Entrada{" "}
            </a>
          </button>
        </div>
        <div className={styles.cardDates}>
          <img src={ticketNormal2} alt="Entrada Ticket Sábado"></img>
          <button>
            <a
              className={styles.buttonBuy}
              rel="noopener noreferrer nofollow"
              target="_blank"
              href="https://barlleno.app/tabs/share/home/brick/AbURPLxFepqLi2MOm1J5">
              Comprar Entrada{" "}
            </a>
          </button>
        </div>
      </div>
      <div>
        <p>Parqueo disponible en</p>
        <img src={presidenteBlue} width={150}></img>
      </div>
    </div>
  );
}
