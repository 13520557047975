import "./App.css";
import "./assets/fonts/MontserratAlternates-BoldItalic.ttf";
import { Navbar } from "./components/navbar/navbar";
import Banner from "./components/banner/banner";
import Artists from "./components/artists/artists";
import Sponsors from "./components/sponsors/sponsors";
import SocialMedia from "./components/socialMedia/socialMedia";
import ComingSoon from "./components/coming/coming";
import DJs from "./components/djs/djs";
import Footer from "./components/footer/footer";

function App() {
  return (
    <div className="App">
      <div className="containerNavbarBanner">
        <Navbar></Navbar>
        <Banner></Banner>
      </div>
      <ComingSoon></ComingSoon>
      {/* <Artists></Artists>
      <DJs></DJs> */}
      <Sponsors></Sponsors>
      {/* <SocialMedia></SocialMedia> */}
      <Footer></Footer>
    </div>
  );
}

export default App;
