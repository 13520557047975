import styles from "./banner.module.css";
import sheraton from "../../assets/sheraton.svg";
import birriaFest from "../../assets/logoBirriaFest.svg";
import presidente from "../../assets/presidente.svg";
import arrow from "../../assets/down_arrow.svg";
import logo from "../../assets/logo.svg";

export default function Banner() {
  return (
    <div>
      <div className={styles.bannerContainer}>
        <img
          src={logo}
          className={styles.bannerImageHide}
          alt="Logo Barlleno"></img>
        <div className={styles.BannerimageSection}>
          <img
            className={styles.middleImage}
            src={birriaFest}
            alt="Birria Fest"></img>
        </div>
        <div className={styles.BannerinfoSection}>
          <img
            src={logo}
            className={styles.logoNavbar}
            alt="Logo Birria Fest"></img>

          <div className={styles.bannerDatesSection}>
            <div>
              <h3>Viernes 6 de Octubre</h3>
              <p style={{ textDecoration: "underline" }}>4 p.m. - 12 a.m.</p>
            </div>
            <div>
              <h3>Sábado 7 de Octubre</h3>
              <p style={{ textDecoration: "underline" }}>12 m - 12 a.m.</p>
            </div>
          </div>
          <h4 className={styles.fontBold}>Sheraton Presidente</h4>
          <p>
            ASISTE AMBOS DÍAS COMPRANDO EL PASE DOBLE. TODAS LAS ENTRADAS
            INCLUYEN 1 CERVEZA GRATIS. PRECIOS NO INCLUYEN CARGOS DE BOLETERÍA{" "}
          </p>
          <div className={styles.bannerImageSectionInfo}>
            <img
              className={styles.cornerImage}
              src={sheraton}
              alt="Hotel Sheraton Presidente"></img>

            <img
              className={styles.cornerImage}
              src={presidente}
              alt="Presidente Plaza"></img>
          </div>
        </div>
      </div>
      <div className={styles.bannerArrow}>
        <p>Compra tus entradas</p>
        <img
          src={arrow}
          width={30}
          className={styles.arrowDown}
          alt="arrow"></img>
      </div>
    </div>
  );
}
